/**
 * This is a minimal mechanism to observe the state of a sticky element and toggle the `is-stuck` class on it.
 *
 * It is a replacement for a very robust `app/assets/modules/github/behaviors/sticky.ts`, and the solution
 * presented there relies on `scroll` event which in turn can be very expensive in terms of performance and messes up
 * the native `scroll-behavior: smooth;` behavior. This is why we were forced to use
 * `app/assets/modules/github/behaviors/smooth-scroll-anchor.ts` instead of the native behavior, which was causing
 * issues with the accessibility and focus order. The mentioned solution was also relying on setting the
 * `position: fixed;` instead of using the `position: sticky;` which is also a native browser behavior.
 *
 * It works by observing the intersection of the element with the viewport and just toggling the `is-stuck`
 * class on the element. The element needs to have a slight negative top (don't forget to add `top: -1px`) value which
 * we use in combination with the intersectionRatio to determine if the element is stuck. Event is fired only once when
 * the element is stuck and once when it is unstuck. It also leans on the native `position: sticky;` behavior and
 * doesn't interrupt the native smooth scrolling behavior.
 *
 * @see app/assets/modules/github/behaviors/sticky.ts
 * @see app/assets/modules/github/behaviors/smooth-scroll-anchor.ts
 *
 * @example
 * ```html
 * <div class="js-sticky-state" style="position: sticky;">
 *  <!-- Your sticky content here -->
 * </div>
 * ```
 */
import {observe} from '@github/selector-observer'

let stickyStateObserverLastYScroll: number = window.scrollY
const stickyStateObserver = new IntersectionObserver(
  ([entry]: IntersectionObserverEntry[]) => {
    if (!entry) return
    const scrollDelta = window.scrollY - stickyStateObserverLastYScroll
    entry.target.classList.toggle('is-stuck', entry.intersectionRatio < 1 && scrollDelta > 0)
    stickyStateObserverLastYScroll = window.scrollY
  },
  {threshold: [1]},
)

observe('.js-sticky-state', {
  add(el) {
    ;(el as HTMLElement).style.top = '-1px'
    stickyStateObserver.observe(el)
  },
  remove(el) {
    stickyStateObserver.unobserve(el)
  },
})
