import './marketing/link-query-param-overrider'
import './marketing/animate-on-scroll'
import './marketing/animate-type'
import './marketing/dialog-close-on-focusout'
import './marketing/header'
import './marketing/scrollnav'
import './marketing/sticky-state-observer'
import './marketing/analytics-events'
import './marketing/faq'
import './marketing/sub-nav'
import './marketing/play-video'

import {lazyDefine} from '@github/catalyst'

lazyDefine({
  'active-global-banners': () => import('./marketing/active-global-banners'),
  'audio-player': () => import('./marketing/audio-player'),
  'before-after': () => import('./marketing/before-after'),
  'card-skew': () => import('./marketing/card-skew'),
  'global-banner': () => import('./marketing/global-banner'),
  'segmented-nav': () => import('./marketing/segmented-nav'),
  'story-body': () => import('./marketing/story-body-element'),
  'testimonials-carousel': () => import('./marketing/testimonials-carousel-element'),
})
